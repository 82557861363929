.download-wrap {
  background: #202030;
  padding: 8px 16px;
}
.download-wrap .name {
  font-size: 18px;
  color: #56faa5;
  margin-bottom: 4px;
}
a:hover,
a:link,
a:visited,
a:active,
a:focus {
  color: ghostwhite;
  text-decoration: none;
}
