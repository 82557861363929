@import 'reset.css';
@font-face {
  font-family: Space Grotesk;
  font-display: fallback;
  src: url(/assets/fonts/SpaceGrotesk-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
body {
  font-family: 'Space Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  background: #000;
}
:root:root {
  --adm-radius-s: 4px;
  --adm-radius-m: 8px;
  --adm-radius-l: 12px;
  --adm-font-size-10: 16px;
  --adm-color-primary: #56faa5;
  --adm-color-success: #00b578;
  --adm-color-warning: #ff8f1f;
  --adm-color-danger: #ff3141;
  --adm-color-text: #fff;
  --adm-color-text-secondary: #adb1b8;
  --adm-color-weak: #999999;
  --adm-color-light: #cccccc;
  --adm-color-border: #313438;
  --adm-color-background: #1c1b26;
  --adm-color-white: #ffffff;
  --adm-color-box: #f5f5f5;
  --adm-color-text-dark-solid: #000000;
  --adm-color-text-light-solid: #000;
  --adm-color-fill-content: var(--adm-color-box);
  --adm-font-size-main: 14px;
  --adm-font-family: 'Space Grotesk', -apple-system, blinkmacsystemfont, 'Helvetica Neue',
    helvetica, segoe ui, arial, roboto, 'PingFang SC', 'miui',
    'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
  --adm-border-color: var(--adm-color-border);
}
.adm-button.adm-button-large {
  height: 48px;
  font-weight: 700;
  --border-radius: var(--adm-radius-m);
}
.adm-button.adm-button-middle {
  height: 40px;
}
.adm-button.adm-button-small {
  height: 32px;
}
.adm-input {
  height: 60px;
  padding: 16px;
  box-sizing: border-box;
  border-radius: var(--adm-radius-m);
  border: 1px solid #202030;
  caret-color: var(--adm-color-primary);
  --background-color: #202030;
  --placeholder-color: #71757a;
  --color: #fff;
}
.adm-input:hover,
.adm-input:active,
.adm-input:focus {
  border: 1px solid var(--adm-color-primary);
}
.adm-search-bar {
  --background: #202030;
}
.adm-search-bar-input.adm-input {
  border: none;
  --background-color: #202030;
}
.custom-input-active {
  border: 1px solid var(--adm-color-primary);
}
html,
body,
#root {
  height: 100%;
}
main {
  max-width: 800px;
  margin: 0 auto;
}
input[type='submit'] {
  display: block;
  margin-top: 1em;
}
.cp {
  cursor: pointer;
}
.df {
  display: flex;
}
.f1 {
  flex: 1;
}
.fdc {
  flex-direction: column;
}
.fdr {
  flex-direction: row;
}
.jcc {
  justify-content: center;
}
.jcsb {
  justify-content: space-between;
}
.jcfs {
  justify-content: flex-start;
}
.jcfe {
  justify-content: flex-end;
}
.aic {
  align-items: center;
}
.aife {
  align-items: flex-end;
}
/* text related */
.tac {
  text-align: center;
}
.tar {
  text-align: right;
}
.tal {
  text-align: left;
}
.fw300 {
  font-weight: 300;
}
.fw600 {
  font-weight: 600;
}
.fwn {
  font-weight: normal;
}
.fs12 {
  font-size: 12px;
}
.fs14 {
  font-size: 14px;
}
.fs16 {
  font-size: 16px;
}
.fs18 {
  font-size: 18px;
  line-height: 26px;
}
.fs24 {
  font-size: 24px;
}
.fs32 {
  font-size: 32px;
}
.t1 {
  color: #121214;
}
.t2 {
  color: #adb1b8;
}
.red {
  color: #ef454a;
}
.green {
  color: #56faa5;
}
.brand {
  color: #ffc701;
}
.mb6 {
  margin-bottom: 6px;
}
.mb16 {
  margin-bottom: 16px;
}
.mb24 {
  margin-bottom: 24px;
}
.mr24 {
  margin-right: 24px;
}
.mr10 {
  margin-right: 10px;
}
.ml10 {
  margin-left: 10px;
}
.mt8 {
  margin-top: 8px;
}
.mt12 {
  margin-top: 12px;
}
.mt16 {
  margin-top: 16px;
}
.mt24 {
  margin-top: 24px;
}
.mt32 {
  margin-top: 32px;
}
.w100 {
  width: 100%;
}
.pd16 {
  padding: 16px;
}
.lh16 {
  line-height: 16px;
}
.lh24 {
  line-height: 24px;
}
.g-hint {
  font-size: 14px;
  line-height: 20px;
  color: #56faa5;
  background: rgba(86, 250, 165, 0.08);
  border-radius: 4px;
  padding: 12px 16px;
}
.btn-default {
  background: #2b2d3e;
  border-color: #2b2d3e;
}
.bborder {
  border-bottom: solid 1px #404347;
}
.iCloud {
  width: 26px;
  height: 18px;
  background: url('../images/icloud.png') no-repeat;
  background-size: 100%;
}
.DropBox {
  width: 24px;
  height: 24px;
  background: url('../images/dropbox.png') no-repeat;
  background-size: 100%;
}
.GoogleDrive {
  width: 26px;
  height: 20px;
  background: url('../images/googledrive.png') no-repeat;
  background-size: 100%;
}
.icon-copy {
  display: inline-block;
  width: 15px;
  height: 15px;
  background: url('../images/union.png') no-repeat;
  background-size: 100%;
  margin: 0 5px;
  cursor: pointer;
}
.point {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #56faa5;
  border-radius: 100%;
  margin-right: 4px;
}
.loading-box {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-box .loading-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  border-radius: 10px;
}
.loading-box .loader {
  display: block;
  width: 64px;
  height: 64px;
  position: relative;
  background-image: linear-gradient(#FFF 16px, transparent 0), linear-gradient(#56FAA5 16px, transparent 0), linear-gradient(#56FAA5 16px, transparent 0), linear-gradient(#FFF 16px, transparent 0);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: left top , left bottom , right top , right bottom;
  animation: rotate 1s linear infinite;
}
@keyframes rotate {
  0% {
    width: 64px;
    height: 64px;
    transform: rotate(0deg);
  }
  50% {
    width: 30px;
    height: 30px;
    transform: rotate(180deg);
  }
  100% {
    width: 64px;
    height: 64px;
    transform: rotate(360deg);
  }
}
