.login-page .header {
  display: flex;
  justify-content: space-between;
}
.login-page .header .account {
  display: flex;
  justify-content: space-between;
}
.login-page .header img {
  height: 33px;
  width: 33px;
  border-radius: 50%;
}
.login-page .header .bitverse {
  border: 1px solid white;
}
.login-page .header .user-info > div:nth-child(1) {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
.login-page .header .user-info > div:nth-child(2) {
  color: #adb1b8;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
}
.login-page .header .user-info .address img {
  margin-left: 5px;
  height: 14px;
  width: 14px;
  vertical-align: bottom;
}
.login-page .connect-logo {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-page .connect-logo .product {
  height: 52px;
  width: 52px;
  border-radius: 50%;
  border: 1px solid white;
}
.login-page .connect-logo > div:nth-child(2) {
  margin-left: 24px;
  margin-right: 24px;
}
.login-page .connect-logo > div:nth-child(2) img {
  vertical-align: middle;
}
.login-page .connect-header {
  margin-top: 40px;
}
.login-page .connect-header .auth {
  color: #56faa5;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
}
.login-page .connect-header .url {
  color: var(--gray-tt-1-title, #fff);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
}
.login-page .connect-header .tip {
  color: var(--gray-tt-2, #adb1b8);
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 30px;
}
.login-page .connect-header .content {
  color: var(--gray-tt-2, #adb1b8);
  font-size: 12px;
  font-weight: 500;
  margin: 16px;
  overflow-wrap: break-word;
}
.login-page .connect-header .content .content-item {
  margin-top: 20px;
}
.login-page .connect-header .content img {
  vertical-align: bottom;
  margin-right: 10px;
}
.login-page .connect-header .content .bottom-tip {
  line-height: 20px;
  margin-top: 24px;
}
.login-page .login-btn {
  margin-top: 30px;
  width: 100%;
  height: 48px;
}
.login-page .confirm-button {
  margin-left: 11px;
}
