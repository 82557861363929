.authorization-container .page-content {
  margin: 47px 20px 45px 20px;
  line-height: 1.2;
}
.authorization-container .page-content .content-tip {
  margin-bottom: 24px;
  line-height: 1.5;
}
.authorization-container .page-content .content-tip h3 {
  margin-bottom: 5px;
}
.authorization-container .page-content .content-detail .row {
  align-items: baseline;
}
.authorization-container .page-content .content-detail .row .label-name {
  width: 18%;
}
.authorization-container .page-content .content-detail .row span {
  min-width: 70px;
}
.authorization-container .page-content .content-detail .row span img {
  margin-left: 2px;
  vertical-align: bottom;
}
.authorization-container .page-content .content-detail .row :first-child {
  margin-right: 55px;
}
.authorization-container .page-content .content-detail .row :nth-child(2) {
  word-break: break-all;
}
.authorization-container .page-content .content-detail .row :not(first-child) {
  padding-top: 16px;
}
.authorization-container .page-content .content-detail .row .detail {
  color: #fff;
}
.authorization-container .page-content .edit-input {
  box-sizing: border-box;
  height: 60px;
  padding: 10px;
  border: 1px solid #00d186;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.04);
}
.authorization-container .page-content .edit-input .adm-input-element {
  color: #fff;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
  -webkit-box-shadow: 0 0 3px 100px #202030 inset;
}
