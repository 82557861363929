.token-wrap {
  overflow: hidden;
}
.token-wrap .adm-tabs-content,
.token-wrap .tokenmanage-list > div {
  padding: 12px 0;
}
.token-wrap .adm-tabs,
.token-wrap .adm-tabs-content,
.token-wrap .tokenmanage-list {
  height: 100%;
}
.token-wrap .adm-tabs .loading,
.token-wrap .adm-tabs-content .loading,
.token-wrap .tokenmanage-list .loading {
  position: relative;
  left: 45%;
  top: 30px;
}
.token-wrap .tokenmanage-list {
  padding: 0 16px;
  overflow-y: scroll;
  height: calc(100vh - 270px);
}
