.wallet-wrap .fs32 {
  font-size: 32px;
  font-weight: 700;
}
.wallet-wrap .adm-tabs-header {
  width: 80px;
  border: 0;
  margin: 22px 0 6px;
  color: #71757A;
}
.wallet-wrap .adm-tabs-header .adm-tabs-tab-active {
  color: #fff;
}
.wallet-wrap .wallet-content {
  padding: 20px 20px 24px;
  background: #202030;
}
.wallet-wrap .wallet-content .asset-switch {
  --height: 16px;
  --width: 32px;
  --border-width: 3px;
}
.wallet-wrap .wallet-content .asset-switch .adm-switch-handle {
  background: #fff;
}
.wallet-wrap .wallet-content .asset-switch.adm-switch-checked .adm-switch-checkbox {
  background: #56faa5;
}
.wallet-wrap .wallet-content .asset-switch .adm-switch-checkbox {
  background: #71757a;
}
.wallet-wrap .wallet-content .asset-switch .adm-switch-checkbox::before {
  background: none;
}
.wallet-wrap .list-wrap {
  z-index: 0;
  position: relative;
}
.wallet-wrap .list-wrap .icon-add {
  z-index: 1;
  position: absolute;
  right: 16px;
  top: 12px;
}
.wallet-wrap .list-wrap .token-lists {
  background: #202030;
  border-radius: 12px;
  padding: 14px 16px;
  line-height: 22px;
  margin-bottom: 12px;
  cursor: pointer;
}
.wallet-wrap .list-wrap .token-lists .icon-wrap {
  z-index: 0;
  position: relative;
}
.wallet-wrap .list-wrap .token-lists .icon-wrap .network-icon {
  z-index: 0;
  position: absolute;
  left: 22px;
  top: 18px;
}
