.send-container .token-img {
  width: 24px;
  height: 24px;
  border-radius: 100%;
}
.send-container .gas-card {
  background-color: #202030;
  border-radius: 8px;
}
.send-container .receive-title {
  display: flex;
  justify-content: space-between;
}
.send-container .receive-title .balance {
  color: #949090;
}
.send-container .tips {
  font-size: 14px;
  color: #f90707;
}
.send-popup .popup-header {
  text-align: center;
  font-size: 16px;
  color: #ffffff;
}
.send-popup .popup-title {
  font-size: 16px;
  color: #ffffff;
}
.send-popup .popup-title img {
  margin-left: 10px;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  vertical-align: middle;
}
.send-popup .popup-title > div {
  display: inline-block;
  line-height: 25px;
  margin-left: 10px;
}
.send-popup .popup-line {
  margin-left: 10px;
  margin-top: 20px;
  font-size: 16px;
  color: #ffffff;
}
.send-popup .popup-line > div {
  line-height: 22px;
  width: 80%;
  word-wrap: break-word;
}
.send-popup .popup-line img {
  float: right;
  margin-top: -40px;
  margin-right: 10px;
}
.send-popup .confirm-button {
  margin-left: 5%;
  width: 90%;
}
.tx-loading {
  margin-top: 50%;
}
