.select-coin-list-container {
  height: calc(100vh - 170px);
  overflow: auto;
}
.select-coin-list-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 32px;
}
