.switch_chain_wrap {
  height: 100%;
  flex-direction: column;
}
.switch_chain_wrap .switch-content {
  margin-top: 40px;
}
.switch_chain_wrap .switch-content .chain-item img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  padding: 8px;
  border: solid 1px #fff;
}
.switch_chain_wrap .switch-icon {
  margin: -24px 18px 0;
}
.switch_chain_wrap .switch-icon i {
  width: 4px;
  height: 4px;
  background: #404347;
  margin-right: 6px;
  border-radius: 10px;
}
.switch_chain_wrap .switch-icon img {
  width: 9px;
  height: 12px;
  margin-right: 6px;
}
