.card {
  margin-bottom: 32px;
  padding: 28px 28px 32px;
  background-color: #fff;
  border-radius: 20px;
  overflow: auto;
}
.card h3 {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 20px;
}
.card p {
  margin-top: 16px;
  color: #34495e;
  font-size: 15px;
  line-height: 26px;
}
.card .mycard-body > div {
  margin-bottom: 16px;
}
