.receive-symbol-icon {
  width: 60px;
  border-radius: 50%;
}
.receive-copy-btn {
  vertical-align: text-top;
}
.backup-hint {
  padding: 100px 60px;
  line-height: 22px;
  text-align: center;
}
