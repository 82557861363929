.token-detail-container .page-content {
  padding: 20px;
  line-height: 1.2;
}
.token-detail-container .page-content .token-info {
  color: #fff;
}
.token-detail-container .page-content .content-detail {
  margin-top: 10px;
  color: #adb1b8;
}
.token-detail-container .page-content .content-detail .detail-info {
  overflow: scroll;
  height: calc(100vh - 360px);
  border-top: 1px solid #ffffff14;
}
.token-detail-container .page-content .content-detail .detail-info .no-data-icon {
  margin-top: 90px;
}
.token-detail-container .page-content .content-detail .detail-info .adm-auto-center {
  box-shadow: 0px 1px 0px 0px #ffffff14;
}
.token-detail-container .page-content .content-detail .detail-info .history-itm {
  padding: 20px;
  box-shadow: 0px 1px 0px 0px #ffffff14;
}
.token-detail-container .page-content .content-detail .detail-info .history-itm .flex-col {
  color: #ffffff;
  line-height: 18px;
}
.token-detail-container .page-content .content-detail .detail-info .history-itm .flex-col :nth-child(2) {
  margin-left: 30px;
  margin-top: 10px;
  color: #71757a;
}
.token-detail-container .page-content .content-detail .detail-info .history-itm .flex-col img {
  margin-right: 10px;
}
.token-detail-container .page-content .content-detail .loading {
  height: calc(100vh - 360px);
}
