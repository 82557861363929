.detail-container .page-content {
  padding: 20px;
  line-height: 1.2;
}
.detail-container .page-content .status-info {
  color: #fff;
}
.detail-container .page-content .content-detail {
  margin-bottom: 30px;
  color: #adb1b8;
}
.detail-container .page-content .content-detail .block-background {
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 8px;
  background: #202030;
}
.detail-container .page-content .content-detail .itm {
  line-height: 18px;
}
.detail-container .page-content .content-detail .itm :first-child {
  margin-right: 30px;
  min-width: 80px;
}
.detail-container .page-content .content-detail .itm :nth-child(2) {
  word-break: break-all;
  text-align: right;
}
.detail-container .page-content .content-detail :last-child {
  margin-bottom: 0;
}
