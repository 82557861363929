.layout-container,
.default-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 500px;
  margin: 0 auto;
  background: #171727;
}
.layout-container .content-wrap,
.default-layout .content-wrap {
  flex: 1;
  overflow-y: auto;
}
.layout-container .adm-nav-bar,
.default-layout .adm-nav-bar {
  height: 69px;
  padding: 0 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.7);
}
.layout-container .adm-nav-bar .adm-nav-bar-back-arrow,
.default-layout .adm-nav-bar .adm-nav-bar-back-arrow {
  font-size: 20px;
}
.layout-container .adm-tab-bar-wrap,
.default-layout .adm-tab-bar-wrap {
  height: 68px;
  padding: 0;
  background: #202030;
}
.left-menu-popup .adm-popup-body {
  width: 320px;
  padding: 40px 16px 0;
}
.left-menu-popup .pdtb16 {
  padding: 16px 0;
}
.left-menu-popup .account-list {
  max-height: calc(100vh - 150px);
  overflow-y: scroll;
  margin-bottom: 12px;
}
.adm-popup .adm-popup-body,
.adm-picker-popup .adm-popup-body {
  background: #000;
}
.adm-popup .adm-popup-body .password-wrap,
.adm-picker-popup .adm-popup-body .password-wrap,
.adm-popup .adm-popup-body .send_wrap,
.adm-picker-popup .adm-popup-body .send_wrap,
.adm-popup .adm-popup-body .adm-picker,
.adm-picker-popup .adm-popup-body .adm-picker {
  max-width: 500px;
  margin: 0 auto;
  background: var(--adm-color-background);
  border-radius: 8px 8px 0 0;
}
.adm-popup .adm-popup-body .send_wrap .header_box,
.adm-picker-popup .adm-popup-body .send_wrap .header_box {
  padding: 16px;
  border-bottom: solid 1px #313438;
  margin-bottom: 16px;
}
