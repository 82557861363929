.g-item-list {
  background: #202030;
  color: #fff;
  font-size: 16px;
  margin-bottom: 12px;
  border-radius: 12px;
  padding: 0 16px;
  overflow: hidden;
}
.g-item-list .cloud-disk-item {
  margin: 16px 0;
}
.backup-wrap {
  z-index: 0;
  position: relative;
  height: 100%;
}
.later-btn {
  text-align: center;
  line-height: 40px;
  color: #D7DCE0;
  border: 1px solid #404347;
  border-radius: 8px;
}
.recovery-wrap {
  background: #171727;
}
.recovery-wrap .wallet-name {
  height: 52px;
}
.recovery-wrap .mb22 {
  margin-bottom: 22px;
}
.customize-toast .adm-toast-main {
  max-width: 300px;
}
