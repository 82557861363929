.token-list {
  padding: 8px 16px;
  line-height: 22px;
  cursor: pointer;
}
.token-list img {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}
